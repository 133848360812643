import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Label } from '../../../_components/Label'
import { ImageInput } from '../../../_components/ImageInput'
import { Alert } from '../../../_components/Alert'
import CompanyViewModel from './CompanyViewModel'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../hooks/useAuth'
import { Button } from '../../../_components/buttons/Button'
import { ButtonGroup } from '../../../_components/ButtonGroup'
import { Tooltip } from '../../../_components/Tooltip'
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline'
import { SAMLConfig } from '../../../../data/model/SAMLConfig'
import { AuthAPI } from '../../../../data/api/AuthAPI'
import { TextInput } from '../../../_components/TextInput'

type Props = {
	viewModel: CompanyViewModel
}

const Company: FC<Props> = ({ viewModel }) => {

	const { REACT_APP_API_URL } = process.env

	const navigate = useNavigate();
	const { workspace } = useAuth()
	const formRef = useRef<HTMLFormElement>(null);
	const formSamlRef = useRef<HTMLFormElement>(null);
	const [show, setShow] = useState(false)
	const [showSamlValidation, setShowSamlValidation] = useState(false)

	const [samlEnabled, setSamlEnabled] = useState(false)

	const [, updateState] = useState<any>()
	const forceUpdate = useCallback(() => updateState({}), [])


	// Called when user changes file
	const onImageUpload = (file: File) => {
		console.log(file)
		viewModel.iconFile = file
	}

	const fetchSamlConfig = useCallback((async () => {
		console.log("fetchSamlConfig")
		const authAPI = new AuthAPI()
		if (workspace?.samlIssuer != undefined && workspace?.samlIssuer != "") {
			workspace.samlConfig = await authAPI.getSamlConfig(workspace?.samlIssuer)
			viewModel.samlConfig = workspace.samlConfig
			forceUpdate()
		}
	}), [workspace])

	useEffect(() => {
		if (samlEnabled) {
			if (workspace != undefined && workspace.samlIssuer != undefined && workspace?.samlIssuer != "") {
				fetchSamlConfig()
			} else {
				viewModel.samlConfig = new SAMLConfig()
			}
		}
	}, [viewModel, samlEnabled, fetchSamlConfig]);

	return (
		<div className='px-6 h-full overflow-y-scroll'>
			<p className='py-4 font-extrabold text-lg'>Manage your workspace settings</p>
			<form ref={formRef} className="max-w-full">
				<div className="mb-5">
					<div className='flex'>
						<div>
							<Label style="md" htmlFor="companyName" label="Workspace name" />
							<TextInput name="companyName" onChange={(e) => { viewModel.name = e.target.value }} value={viewModel.name} />
						</div>

					</div>
					<div className='flex mt-4'>
						<div>
							<Label style="md" htmlFor="companyContact" label="Workspace contact" />
							<div className="flex place-items-center w-full">
								<TextInput name="companyContact" onChange={(e) => { viewModel.name = e.target.value }} value={viewModel.email} />
								<div className="ms-2 p-2 text-gray-800 bg-red-50 rounded-md flex items-center text-sm">
									<p><strong>Be careful</strong> changing the contact will <strong>transfer</strong> the administration of the workspace</p>
								</div>
							</div>
						</div>
					</div>


					<ImageInput customClass='mt-5' label="Workspace icon" name={''} initialeImage={viewModel.icon} onImageUpload={onImageUpload} />

				</div>

			</form>

			{/* 
				TODO uncomment when Saml config validation is ready

			<>
				<div className='border-b-1'></div>
				<div className='mt-2'>
					<h2 className='font-extrabold text-lg pt-4 pb-1'>Authentication</h2>
					<p className='text-gray-400 pb-2'>At least one authentication method must be configured.</p>
				</div>

			

				<div>
					<div className="inline-flex w-full items-center">
						<span className='font-extrabold w-1/6 pt-4 pb-4'>Deliver auth</span>

						<ButtonGroup index={viewModel.deliverAuthEnabled ? 1 : 0} customClass={"ms-5"} mode={"toggle"} onClick={(selection) => {
							viewModel.deliverAuthEnabled = selection.includes(1)
						}}>
							<Tooltip content="Enable method">
								<LockClosedIcon className="size-4" onClick={console.log} />
							</Tooltip>
							<Tooltip content="Disable method">
								<LockOpenIcon className="size-4" onClick={console.log} />
							</Tooltip>
						</ButtonGroup>
					</div>
				</div>

				<div>
					<div className="inline-flex w-full items-center">
						<span className='font-extrabold w-1/6 pt-4 pb-4'>Google</span>

						<ButtonGroup index={viewModel.googleAuthEnabled ? 1 : 0} customClass={"ms-5"} mode={"toggle"} onClick={(selection) => {
							viewModel.googleAuthEnabled = selection.includes(1)
						}}>
							<Tooltip content="Enable method">
								<LockClosedIcon className="size-4" onClick={console.log} />
							</Tooltip>
							<Tooltip content="Disable method">
								<LockOpenIcon className="size-4" onClick={console.log} />
							</Tooltip>
						</ButtonGroup>
					</div>
				</div>

				<div>
					<div className="inline-flex w-full items-center">
						<span className='font-extrabold w-1/6 pt-4 pb-4'>SAML</span>

						<ButtonGroup index={viewModel.samlAuthEnabled ? 1 : 0} customClass={"ms-5"} mode={"toggle"} onClick={(selection) => {
							viewModel.samlAuthEnabled = selection.includes(1)
							setSamlEnabled(viewModel.samlAuthEnabled)
						}}>
							<Tooltip content="Enable method">
								<LockClosedIcon className="size-4 p-0" />
							</Tooltip>
							<Tooltip content="Disable method">
								<LockOpenIcon className="size-4" />
							</Tooltip>
						</ButtonGroup>
					</div>
				</div>

				{
					samlEnabled && (
						<form ref={formSamlRef} className="max-w-full mb-5" action={`${REACT_APP_API_URL}/auth/samlConfig`} method="POST">
							<div className="mb-5">
								<div className='flex'>
									<div>
										<Label style="md" htmlFor="issuerName" label="Issuer name" />
										<input required type="string" id="issuerName" onChange={(e) => {
											if (viewModel.samlConfig) {
												viewModel.samlConfig.issuerName = e.target.value
											}
										}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={viewModel.samlConfig?.issuerName} />
									</div>

								</div>
								<div className='flex mt-4'>
									<div>
										<Label style="md" htmlFor="issuer" label="SAML Issuer" />
										<div className="flex place-items-center">
											<input required type="string" id="issuer" name="issuer" onChange={(e) => {
												if (viewModel.samlConfig) {
													viewModel.samlConfig.issuer = e.target.value
												}
											}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" defaultValue={viewModel.samlConfig?.issuer} />
										</div>
									</div>
								</div>
								<div className='flex mt-4'>
									<div>
										<Label style="md" htmlFor="entryPoint" label="SAML Entry Point" />
										<div className="flex place-items-center">
											<input required type="string" id="entryPoint" onChange={(e) => {
												if (viewModel.samlConfig) {
													viewModel.samlConfig.entryPoint = e.target.value
												}
											}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" defaultValue={viewModel.samlConfig?.entryPoint} />
										</div>
									</div>
								</div>
								<div className='flex mt-4'>
									<div>
										<Label style="md" htmlFor="cert" label="SAML Cert" />
										<div className="flex place-items-center">
											<input required type="string" id="cert" onChange={(e) => {
												if (viewModel.samlConfig) {
													viewModel.samlConfig.cert = e.target.value
												}
											}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" defaultValue={viewModel.samlConfig?.cert} />
										</div>
									</div>
								</div>
							</div>
						</form>
					)
				}
			


			<Button customClass="flex mb-1 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" text={"Check SAML configuration"} onClick={() => {
				if (formSamlRef.current) {
					if (formSamlRef.current.reportValidity()) {
						setShowSamlValidation(true)
					}
				} else {
					setShowSamlValidation(true)
				}
			}} />
			 </> 
			 */}

			<Button text={viewModel.company ? 'Update workspace' : 'Create workspace'} onClick={() => {
				if (formRef.current && formRef.current.reportValidity()) {
					setShow(true)
					// TODO uncomment when Saml config validation is ready
					// if (formSamlRef.current) {
					// 	if (formSamlRef.current.reportValidity()) {
					// 		setShow(true)
					// 	}
					// } else {
					// 	setShow(true)
					// }
				}
			}} />


			{/* Update pr create workspace */}

			<Alert visible={show} title={viewModel.company ? `Updating ${viewModel.name}` : `Creating ${viewModel.name}`} message={'Are you sure ?'}
				primaryButtonTitle={'Yes'} primaryCallback={async () => {

					viewModel.onValidate().then(() => {
						navigate("/store")
					}).catch(() => {
						console.error("An error occured while creating/updating the workspace")
					})
				}}
				secondaryButtonTitle='Cancel' secondaryCallback={() => { setShow(false) }}
				closable={false}
			/>

			{/* Update pr create workspace */}

			<Alert visible={showSamlValidation} title={`Validating SAML config for issuer ${viewModel.samlConfig?.issuer}`} message={'Are you sure ?'}
				primaryButtonTitle={'Yes'} primaryCallback={async () => {
					await viewModel.onValidateSAMLConfig()
				}}
				secondaryButtonTitle='Cancel' secondaryCallback={() => { setShow(false) }}
				closable={false}
			/>


		</div>
	)
}

export { Company }


