import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Filter } from "./_components/Filter"
import { BuildList } from "./_components/buildlist/BuildList"
import { useParams } from "react-router-dom"
import { AppAPI } from "../../../../data/api/AppAPI"
import { Build } from "../../../../data/model/Build"
import Lottie from 'react-lottie'
import emptyBuildAnimation from '../../../../assets/animations/empty-build.json'
import issuesNotFoundAnimation from '../../../../assets/animations/issues-not-found.json'
import { BuildHeader } from "./_components/buildheader/BuildHeader"
import { BackLink } from "../../../_components/BackLink"
import { BuildUseCases } from "../../../../domain/usecases/BuildUseCases"
import { App } from "../../../../data/model/App"
import { AppPageViewModel } from "./AppPageViewModel"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"

/**
 * This page display basic application information and all available buillds
 */
const AppPage: FC = observer(() => {

	const { appId } = useParams()
	const viewModel = useMemo(() => new AppPageViewModel(appId), [appId]);

	const emptyBuildAnimationOptions = {
		loop: true,
		autoplay: true,
		animationData: emptyBuildAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	}
	const buildsNotFoundAnimationOptions = {
		loop: true,
		autoplay: true,
		animationData: issuesNotFoundAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	}
	useEffect(() => {
		viewModel.load()
	}, [appId])

	return (
		<div className="px-6 pt-8 h-full overflow-y-scroll">
			<BackLink text="Back to store" customClass="mb-10" />
			{
				viewModel.builds.length > 0 ? (
					<>
						{/* <BuildHeader /> */}
						<Filter viewModel={viewModel} />

						{
							(viewModel.app && viewModel.filteredBuilds.length > 0) ? (
								<BuildList app={viewModel.app} builds={viewModel.filteredBuilds} />
							) : (
								<div className="items-center text-center">
									<Lottie options={buildsNotFoundAnimationOptions} height={150} width={200} />
									<div className="text-2xl font-bold mt-5">No builds match your filters</div>
								</div>

							)
						}
					</>
				) : (
					<div className="items-center text-center">
						<Lottie options={emptyBuildAnimationOptions} height={350} width={400} />
						<div className="text-2xl font-bold mt-10">No build found</div>
					</div>
				)
			}
		</div>
	)
})

export { AppPage }