import { FC } from "react"
import { Outlet } from "react-router-dom"
import { NavigationTabs } from "../../../../_components/NavigationTabs/Tabs"
import { NavigationTab } from "../../../../_components/NavigationTabs/Tab"

const BuildLayout: FC = () => {

	return (
		<div className={`h-full overflow-scroll w-full`}>
			<div className={`pt-8 h-full flex flex-col px-6 w-full`}>
				<NavigationTabs customClass="pt-5">
					<NavigationTab to={"overview"} title={"Overview"} />
					<NavigationTab to={"issue"} title={"Issues"} />
					{/* <NavigationTab to={"crash"} title={"Crashes"} /> */}
					<NavigationTab to={"security"} title={"Security"} />
					<NavigationTab to={"live"} title={"Live log"} />
					<NavigationTab to={"network"} title={"Network"} />
					<NavigationTab to={"performance"} title={"Performance"} />
				</NavigationTabs>

				{ /** pb-40 is a very dirty trick to show entire scrollview, fuck CSS */}
				<div className="pl-2 h-full">
					<Outlet />
				</div>
			</div>
		</div>
	)
}

export { BuildLayout }