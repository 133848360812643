import axios from 'axios'

export const axiosInstance = axios.create({
	baseURL: '/api',
})

axiosInstance.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		if (error.response != undefined) {
			if (401 === error.response.status || 403 === error.response.status) {
				if (window.location.href.indexOf('auth') < 0) window.location.href = '/auth/login'
			} else {
				return Promise.reject(error)
			}
		}
	}
)
