import { FC, useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Build } from "../../../../../data/model/Build"
import { App, AppType } from "../../../../../data/model/App"
import { Avatar } from "../../../../_components/avatar/Avatar";
import { LinkIcon, UserGroupIcon } from "@heroicons/react/24/outline"
import { QRCode } from 'react-qrcode-logo';
import { ReactComponent as AppleLogo } from '../../../../../assets/images/apple-logo.svg'
import { ReactComponent as AndroidLogo } from '../../../../../assets/images/android-logo.svg'
import { Tester } from "../../../../_components/tester/Tester";
import { Tooltip } from "../../../../_components/Tooltip";
import { BuildAPI } from "../../../../../data/api/BuildAPI";
import { BuildUseCases } from "../../../../../domain/usecases/BuildUseCases";
import { AppAPI } from "../../../../../data/api/AppAPI";
import { UserAPI } from "../../../../../data/api/UserAPI";
import { User } from "../../../../../data/model/User";

const BuildOverview: FC = () => {

	const [app, setApp] = useState<App>()
	const [build, setBuild] = useState<Build>()
	const [committer, setCommitter] = useState<User>()
	const { appId, buildId } = useParams()

	const getBuild = useCallback((async () => {
		const buildAPI = new BuildAPI()
		const buildUseCases = new BuildUseCases()

		const response = await buildAPI.getBuildById(buildId!)
		const build = await buildUseCases.assembleBuild(response)
		setBuild(build)
	}), [buildId])

	const getCommitter = useCallback((async () => {
		if (build?.committerEmail != undefined) {
			const userAPI = new UserAPI()
			const response = await userAPI.getUserByEmail(build?.committerEmail)
			console.log(`BuildOverview fetch commiter response :${response}`)
			setCommitter(response)
		}
	}), [build])

	const getApp = useCallback((async () => {
		const appdAPI = new AppAPI()
		const response = await appdAPI.app(appId!)
		console.log(`BuildOverview fetch app response :${response}`)
		setApp(response)
	}), [buildId])


	useEffect(() => {
		getApp()
		getBuild()
	}, [buildId])

	useEffect(() => {
		getCommitter()
	}, [build])

	return (
		<div>
			<div className="inline-flex w-full pb-4 justify-between">
				<div>
					<h2 className="font-bold text-xl">Build overview</h2>
					<div className="flex align-middle items-center">
						{/* Already in breadcrumbs, keep it ? */}
						<p className="font-bold text-lg">{app?.name} #{build?.version}</p>
						{app?.platform === AppType.IOS ? <AppleLogo className="inline size-4 items-center ml-1" /> : <AndroidLogo className="inline size-4 items-center ml-1" />}
					</div>
				</div>
				<QRCode
					value="https:www.lefigaro.com"
					removeQrCodeBehindLogo={true}
					logoPadding={5}
					fgColor="rgb(100 100 100)"
					qrStyle="dots"
					eyeColor="rgb(255 150 250)"
					eyeRadius={5}
					size={150} />
			</div>
			<div className="space-y-5">
				<div className="">
					<div className="font-bold ">Customer issue</div>
					<p className="font-semibold">
						{
							app?.jiraUrl ? <Link to={`${app?.jiraUrl}${build?.customerIssue}`} onClick={(event) => { event.stopPropagation() }}>
								<div className="w-fit flex flex-row space-x-1 hover:text-blue-600 hover:underline">
									<p className={`font-bold`}>{build?.customerIssue !== "unknown" ? `#${build?.customerIssue}` : "-"}</p>
									<LinkIcon className="size-4" />
								</div>
							</Link> :
								<Tooltip content={'To be linked to your Jira instance, please go configure Integration in your app settings'}>
									<div className="w-fit flex flex-row space-x-1 hover:text-blue-600 hover:underline">
										<p className={`font-bold`}>{build?.customerIssue !== "unknown" ? `#${build?.customerIssue}` : "-"}</p>
										<LinkIcon className="size-4" />
									</div>
								</Tooltip>
						}
					</p>
				</div>

				{/* TODO Uncomment when data are usefull <Cards /> */}

				{/* 
				<div className="flex justify-between align-middle">
					<p><span className="font-semibold">Commit hash:</span> {build?.commitHash}</p>
					<Moment className="text-sm" format="YYYY/MM/DD hh:mm:ss">{build?.createdAt}</Moment>
				</div> */}
				<div className="grid grid-cols-3 align-middle gap-4">
					<div className="border-1 rounded-lg p-3 text-center">
						<div className="font-semibold pb-2 text-gray-600">Pipeline</div>
						<div className="items-center flex justify-center gap-2 hover:text-blue-800  font-bold">
							<LinkIcon className="size-3" />
							{
								app?.gitlabUrl ? <Link to={`${app?.gitlabUrl}/-/pipelines/${build?.pipelineId}`} onClick={(event) => { event.stopPropagation() }}>
									<div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">#{build?.pipelineId}</div>
								</Link> :
									<Tooltip content={'To be linked to your gitlab instance, please go configure Integration in your app settings'}>
										<div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">#{build?.pipelineId}</div>
									</Tooltip>
							}
						</div>
					</div>
					<div className="border-1 rounded-lg p-3 text-center">
						<div className="font-semibold pb-2 text-gray-600">Commit hash</div>
						<div className="items-center flex justify-center gap-2 hover:text-blue-800  font-bold">
							<LinkIcon className="size-3" />
							{
								app?.gitlabUrl ? <Link to={`${app?.gitlabUrl}/-/commit/${build?.commitHash}`} onClick={(event) => { event.stopPropagation() }}>
									<div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">#{build?.commitHash}</div>
								</Link> :
									<Tooltip content={'To be linked to your gitlab instance, please go configure Integration in your app settings'}>
										<div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">#{build?.commitHash}</div>
									</Tooltip>
							}
						</div>
					</div>
					<div className="border-1 rounded-lg p-3 text-center">
						<div className="font-semibold pb-2 text-gray-600">Commiter</div>
						<div className="flex justify-center">
							<Tooltip content={build?.committerEmail}>
								<span
									className="text-primary dark:text-primary-400">
									{committer && <Avatar user={committer} />}
								</span>
							</Tooltip>
						</div>
					</div>
				</div>
				<div>
					<div className="font-bold ">Testers</div>
					<div className="flex -space-x-2 overflow-hidden items-center">
						{
							build && build.testers.length > 0 ? (
								build?.testers.map(tester => (
									<Tester
										key={tester.id} // Always use a unique key when rendering lists in React
										user={tester}
										customClass="inline-block h-8 w-8 rounded-full"
										haveValidated={build?.validatorsId.includes(tester.id!)}
										haveRefused={build?.deniesId.includes(tester.id!)}
									/>
								))
							) : (
								<div className="flex flex-row items-center gap-1">
									<UserGroupIcon className="size-4" />
									<p>No testers yet</p>
								</div>

							)
						}
					</div>
				</div>
				<div>
					<div className="font-bold">Branch</div>
					<div> {build?.branch}</div>
				</div>
				<div>
					<div className="font-bold">Commit message</div>
					<p className="bg-slate-50 w-full rounded-md p-4 -ml-2">{build?.commitMessage}</p>

				</div>
				<div>
					<div className="font-bold">Change log</div>
					<p className="bg-slate-50 w-full rounded-md p-4 -ml-2">{build?.changeLog}</p>

				</div>



			</div>
		</div>
	)
}


const Cards: FC = () => {
	return (
		<>
			{ /** Cards */}
			<div className="grid grid-cols-4 gap-4">
				{/*  Weekly download */}
				<div className="p-1 rounded-lg bg-orange-100 text-orange-500">
					<div className="flex flex-row space-x-2 items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-6 rounded-md">
							<path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75ZM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 0 1-1.875-1.875V8.625ZM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 0 1 3 19.875v-6.75Z" />
						</svg>
						<p className="inline items-center font-bold text-xl me-1 pt-1">-</p>
					</div>
					<p className="text-sm font-medium text-center">Crash Free</p>
				</div>
				{/*  Bug Reports */}
				<div className="p-1 bg-green-100 text-green-500 border-green-200 rounded-lg">
					<div className="flex flex-row space-x-1 items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
							<path fillRule="evenodd" d="M8.478 1.6a.75.75 0 0 1 .273 1.026 3.72 3.72 0 0 0-.425 1.121c.058.058.118.114.18.168A4.491 4.491 0 0 1 12 2.25c1.413 0 2.673.651 3.497 1.668.06-.054.12-.11.178-.167a3.717 3.717 0 0 0-.426-1.125.75.75 0 1 1 1.298-.752 5.22 5.22 0 0 1 .671 2.046.75.75 0 0 1-.187.582c-.241.27-.505.52-.787.749a4.494 4.494 0 0 1 .216 2.1c-.106.792-.753 1.295-1.417 1.403-.182.03-.364.057-.547.081.152.227.273.476.359.742a23.122 23.122 0 0 0 3.832-.803 23.241 23.241 0 0 0-.345-2.634.75.75 0 0 1 1.474-.28c.21 1.115.348 2.256.404 3.418a.75.75 0 0 1-.516.75c-1.527.499-3.119.854-4.76 1.049-.074.38-.22.735-.423 1.05 2.066.209 4.058.672 5.943 1.358a.75.75 0 0 1 .492.75 24.665 24.665 0 0 1-1.189 6.25.75.75 0 0 1-1.425-.47 23.14 23.14 0 0 0 1.077-5.306c-.5-.169-1.009-.32-1.524-.455.068.234.104.484.104.746 0 3.956-2.521 7.5-6 7.5-3.478 0-6-3.544-6-7.5 0-.262.037-.511.104-.746-.514.135-1.022.286-1.522.455.154 1.838.52 3.616 1.077 5.307a.75.75 0 1 1-1.425.468 24.662 24.662 0 0 1-1.19-6.25.75.75 0 0 1 .493-.749 24.586 24.586 0 0 1 4.964-1.24h.01c.321-.046.644-.085.969-.118a2.983 2.983 0 0 1-.424-1.05 24.614 24.614 0 0 1-4.76-1.05.75.75 0 0 1-.516-.75c.057-1.16.194-2.302.405-3.417a.75.75 0 0 1 1.474.28c-.164.862-.28 1.74-.345 2.634 1.237.371 2.517.642 3.832.803.085-.266.207-.515.359-.742a18.698 18.698 0 0 1-.547-.08c-.664-.11-1.311-.612-1.417-1.404a4.535 4.535 0 0 1 .217-2.103 6.788 6.788 0 0 1-.788-.751.75.75 0 0 1-.187-.583 5.22 5.22 0 0 1 .67-2.04.75.75 0 0 1 1.026-.273Z" clipRule="evenodd" />
						</svg>
						<p className="inline items-center font-bold text-xl me-1 pt-1">-</p>
					</div>
					<p className="text-sm font-medium text-center">Issues</p>
				</div>
				{/*  Security */}
				<div className="p-1 bg-rose-100 border-rose-200 text-rose-500 rounded-lg">
					<div className="flex flex-row space-x-1 items-center  justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
							<path fillRule="evenodd" d="M11.484 2.17a.75.75 0 0 1 1.032 0 11.209 11.209 0 0 0 7.877 3.08.75.75 0 0 1 .722.515 12.74 12.74 0 0 1 .635 3.985c0 5.942-4.064 10.933-9.563 12.348a.749.749 0 0 1-.374 0C6.314 20.683 2.25 15.692 2.25 9.75c0-1.39.223-2.73.635-3.985a.75.75 0 0 1 .722-.516l.143.001c2.996 0 5.718-1.17 7.734-3.08ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75ZM12 15a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75H12Z" clipRule="evenodd" />
						</svg>
						<p className="inline items-center font-bold text-xl me-1 pt-1">-</p>
					</div>
					<p className="text-sm font-medium text-center">Security</p>
				</div>
				<div className="p-1 bg-fuchsia-100 border-fuchsia-200 text-fuchsia-700 rounded-lg">
					<div className="flex flex-row space-x-1 items-center  justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
							<path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
						</svg>

						<p className="inline items-center font-bold text-xl me-1 pt-1">-</p>
					</div>
					<p className="text-sm font-medium text-center">Crash</p>
				</div>
			</div>
		</>
	)

}

export { BuildOverview }