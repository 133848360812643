import { makeObservable, observable } from "mobx"
import { App } from "../../../../data/model/App"
import { AppAPI } from "../../../../data/api/AppAPI"
import { BuildUseCases } from "../../../../domain/usecases/BuildUseCases"
import { Build } from "../../../../data/model/Build"


class AppPageViewModel {

	public isLoading: boolean = false

	public app: App | undefined
	public builds: Build[] = []
	public filteredBuilds: Build[] = []

	private appId: string | undefined

	constructor(appId: string | undefined) {
		makeObservable(this, {
			builds: observable,
			filteredBuilds: observable,
			isLoading: observable
		})
		this.appId = appId
	
	}

	public setFilteredBuilds(builds: Build[] | undefined) {
		if (builds) {
			this.filteredBuilds = builds
		}
		else {
			this.filteredBuilds = this.builds
		}
	}

	public load() {
		try {
			(async () => {
				if (this.appId != undefined) {
					this.isLoading = true
					const appAPI = new AppAPI()
					const buildUseCases = new BuildUseCases()
					const builds = await appAPI.builds(this.appId)
					const app = await appAPI.getAppById(this.appId)
					console.debug(`[BuildList] build for ${this.appId} : ${(await builds).length} builds`)
					await Promise.all(
						builds.map(async (build) => {
							return await buildUseCases.assembleBuild(build)
						})
					)
					this.app = app
					this.builds = builds
					this.filteredBuilds = this.builds
					this.isLoading = false
				}
			 })()
		}
		catch (error) { }
		finally { 
			this.isLoading = false
		}
	}
}

export { AppPageViewModel }