
import { Await } from "react-router-dom"
import { AuthAPI } from "../../../../data/api/AuthAPI"
import { CompanyAPI } from "../../../../data/api/CompanyAPI"
import { AuthMethod, Company } from "../../../../data/model/Company"
import { SAMLConfig } from "../../../../data/model/SAMLConfig"

class CompanyViewModel {
	public company?: Company
	public samlConfig?: SAMLConfig

	public name: string = ''
	public email: string = ''
	public iconFile?: File
	public icon?: string = "default"

	public deliverAuthEnabled: boolean = true
	public googleAuthEnabled: boolean = false
	public samlAuthEnabled: boolean = false

	constructor(company?: Company) {
		console.info(`Create viewModel with company: ${company}`)

		if (company != null) {

			this.company = company
			this.name = company.name || ''
			this.email = company.email || ''
			this.icon = `/api/img/${company.icon}`
			this.iconFile = company.iconFile

			this.deliverAuthEnabled = company.authMethods?.includes(AuthMethod.DELIVER) == true
			this.googleAuthEnabled = company.authMethods?.includes(AuthMethod.GOOGLE) == true
			this.samlAuthEnabled = company.authMethods?.includes(AuthMethod.SAML) == true && company.samlIssuer != undefined

			console.log(company)
		}
	}

	async onValidate(): Promise<Company | undefined> {
		const api = new CompanyAPI()
		const authAPI = new AuthAPI()
		var createdOrUpdatedCompany: Company
		const authMethods = []

		if (this.deliverAuthEnabled) authMethods.push(AuthMethod.DELIVER)
		if (this.googleAuthEnabled) authMethods.push(AuthMethod.GOOGLE)
		if (this.samlAuthEnabled) authMethods.push(AuthMethod.SAML)

		if (this.company != null) {
			createdOrUpdatedCompany = await api.updateCompany(this.company.id, this.name, this.iconFile, authMethods, this.samlConfig?.issuerName)
		} else {
			createdOrUpdatedCompany = await api.createCompany(this.name, this.iconFile, authMethods, this.samlConfig?.issuerName)
		}

		debugger
		if (createdOrUpdatedCompany != undefined) {
			if (this.samlAuthEnabled && this.samlConfig != undefined) {
				const createdSamlConfig = await authAPI.samlConfig(this.samlConfig)
				if (createdSamlConfig == undefined) {
					return undefined
				}
			}
		}

		return createdOrUpdatedCompany
	}

	async onValidateSAMLConfig(): Promise<void> {
		const api = new CompanyAPI()
		const authAPI = new AuthAPI()

		if (this.samlAuthEnabled && this.samlConfig != undefined) {
			const ssoURL = await authAPI.samlSSOUrl(this.samlConfig)
			window.location.href = ssoURL
		}
	}
}

export default CompanyViewModel