import { FC, useEffect, useMemo, useState } from "react"
import { Avatar } from "../../../_components/avatar/Avatar"
import { UserIcon } from "@heroicons/react/24/solid"
import { observer } from "mobx-react-lite"
import { useAuth } from "../../../../hooks/useAuth"
import { StorePageViewModel } from "../../store/store/StorePageViewModel"
import { ChevronLeftIcon, PlusIcon, SquaresPlusIcon } from "@heroicons/react/24/outline"
import { Button } from "../../../_components/buttons/Button"
import { AppCard } from "../../store/store/_components/appcard/AppCard"
import { Loader } from "../../../_components/Loader"
import { App, AppType } from "../../../../data/model/App"
import { Link } from "react-router-dom"
import { AppAvatar } from "../../../_components/AppsAvatar"
import { ReactComponent as AppleLogo } from '../../../../assets/images/apple-logo.svg'
import { ReactComponent as AndroidLogo } from '../../../../assets/images/android-logo.svg'
import { useMobileOrientation, isAndroid } from "react-device-detect"
import { MobileBuildsList } from "../builds/MobileBuildsList"
import { MobileProfile } from "../profile/MobileProfile"

const MobileStore: FC = observer(()=> {

	const { isLandscape } = useMobileOrientation()

	const { workspace, setWorkspace } = useAuth()
	const viewModel = useMemo(() => new StorePageViewModel(workspace), [workspace]);

	const [selectedApp, setSelectedApp] = useState<string|null>(null)

	const [isOpen, setIsOpen] = useState(false);

  	const openModal = () => setIsOpen(true);
  	const closeModal = () => setIsOpen(false);


	useEffect(() => {		
		viewModel.load(isAndroid ? [AppType.ANDROID] : [AppType.IOS])
	}, [workspace])

	return (
		<div className="h-full flex flex-col">
			<div>
				<header className={`w-full ${isLandscape ? "h-[80px] pt-safe-left" : "h-[100px] pt-safe-top" }  bg-white fixed border px-3 pb-5 flex flex-row justify-between items-center`}>
					<div>
						<button onClick={ () => setSelectedApp(null)}>
						<ChevronLeftIcon className={`size-6 ${selectedApp ? "opacity-100" : "opacity-0"}`} />
						</button>
					</div> 
					<h1 className="text-2xl font-black text-center">Store</h1>
					<button onClick={openModal} >
						<UserIcon className="size-5"/>
					</button>
				</header>
				
				<div className={`flex flex-col p-4 ${isLandscape ? "mt-[80px] px-16" : "mt-[100px]"} `}>
					<div className="flex flex-col gap-4">
					{
						viewModel.isLoading
							? ( <Loading /> )
							: (
								<>
									{
										viewModel.groupedApps
											? Object.entries(viewModel.groupedApps).map(([group, apps]) => {
												return (
													<div key={group}>
														<div className="cursor-pointer">
															{
																apps?.map((app) => {
																	return (
																		<button onClick={() => setSelectedApp(app.id) } className="w-full">
																			<MobileAppCard app={app} key={app.id} />
																		</button>
																	)
																})
															}
														</div>
													</div>
												)
											})
											: (<EmptyData />)
									}
								</>
							)
					}
					</div>
				</div>
			
			</div>
			<MobileProfile isOpen={isOpen} onClose={closeModal} onChangeWorkspace={function (workspace): void {
				setWorkspace(workspace);
			} } />
		</div>
	)
})


const EmptyData: FC = () => {

	return (
		<div className="flex flex-col justify-center items-center h-full">
			<div className="p-7 bg-gradient-to-b from-slate-100 to-transparent rounded-full">
				<SquaresPlusIcon className="size-20" />
			</div>
			<div className="flex flex-col justify-center items-center mt-6">
				<p className="font-extrabold text-5xl mb-3">Create your first app 🚀</p>
				<p className="text-gray-500 text-center max-w-[600px] mb-5">Your workspace is empty! Get started by creating your first application and ake control of your mobile applications with powerful management tools.</p>
				{/* <Button type="dark" size="lg" onClick={() => setCreateAppModalVisible(true)}>Create my first app</Button> */}
			</div>
		</div>
	)
}


const Loading: FC =() => {
	
	return (
		<div className="flex flex-col justify-center items-center h-full">
			<div className="flex flex-row items-center gap-3">
				<Loader size="huge" />
				<p className="text-4xl font-extrabold">Loading your workspace</p>
			</div>
		</div>
	)
}


type Props = {
	app: App
}

const MobileAppCard: FC<Props> = ({app})=> {

	return (
		<Link to={`app/${app.id}`}>
			<div className="card border-1 border-[#E4E6EF] p-6 rounded-lg w-full">
				<div className="flex flex-row space-x-4 items-center">
					<AppAvatar app={app} size="lg" />
					<div>
						<div className="flex items-center">
							<h3 className="font-bold text-xl me-1">{app.name}</h3>
							{app.platform === AppType.IOS ? <AppleLogo className="size-4 mb-1" /> : <AndroidLogo className="size-4" />}
						</div>
						<p className="text-sm font-medium text-gray-400">{ app.bundleId }</p>
					</div>
				</div>
			</div>
		</Link>
	)
}
export { MobileStore, MobileAppCard }