import { FC, useCallback, useEffect, useState } from "react";
import { Logo } from "../../_components/logo/logo";
import './auth.css';
import { CompanyAPI } from "../../../data/api/CompanyAPI";
import { AuthMethod, Company } from "../../../data/model/Company";
import { Button } from "../../_components/buttons/Button";

enum AuthentStrategy {
	SAML, GOOGLE, DELIVER
}

const AuthPage: FC = () => {

	const { REACT_APP_API_URL } = process.env

	const getWorkspaceForAuth = useCallback((async () => {
		console.log("getWorkspaceForAuth")
		const companyApi = new CompanyAPI()
		const getWorkspaceForAuthRes = await companyApi.getWorkspaceForAuth()
		setAuthState({ workspaceForAuth: getWorkspaceForAuthRes });
	}), [])

	useEffect(() => {
		getWorkspaceForAuth()
	}, [getWorkspaceForAuth]);

	const [authState, setAuthState] = useState<{ workspaceForAuth?: Company }>({
		workspaceForAuth: undefined
	});

	return (
		<div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<Logo customClass="text-center" size="2xl" />
				{
					authState.workspaceForAuth ? (<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to {authState.workspaceForAuth.name}</h2>) : <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
				}
			</div>
			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

				{
					(authState.workspaceForAuth === undefined || authState.workspaceForAuth?.authMethods?.includes(AuthMethod.SAML)) && (
						<form className="space-y-6" action={`${REACT_APP_API_URL}/auth/saml`} method="POST">

							<div>
								<label htmlFor="issuer" className="block text-sm font-medium leading-6 text-gray-900">Issuer</label>
								<div className="mt-2">
									{
																					<input id="issuer" name="issuer" type="text" defaultValue={authState.workspaceForAuth?.samlIssuer || ""} required className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />

									}
								</div>
							</div>
							<div>
								<button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 p-2 px-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Continue with SAML</button>
							</div>
						</form>
					)
				}
				{/* {
					(workspaceForAuth == undefined || workspaceForAuth?.authMethods?.includes(AuthMethod.DELIVER)) && (
						<form className="space-y-6" action="#" method="POST">
							<div>
								<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
								<div className="mt-2">
									<input id="email" name="email" type="email" autoComplete="email" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
								</div>
							</div>
							<div>
								<div className="flex items-center justify-between">
									<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
									<div className="text-sm">
										<a href="/" className="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
									</div>
								</div>
								<div className="mt-2">
									<input id="password" name="password" type="password" autoComplete="current-password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
								</div>
							</div>
							<div>
								<button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign in</button>
							</div>
						</form>
					)
				} */}

				{
					(authState.workspaceForAuth === undefined || authState.workspaceForAuth?.authMethods?.includes(AuthMethod.GOOGLE)) && (
						<div className="flex flex-col items-center mt-10">
							<a href={`${REACT_APP_API_URL}/auth/google`} className="flex w-full justify-center border-1 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
								<svg width="20" height="25" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: "block" }}>
									<path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
									<path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
									<path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
									<path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
									<path fill="none" d="M0 0h48v48H0z"></path>
								</svg>
								<span className="ms-2">Continue with Google</span>
							</a>
						</div>
					)
				}

				{
					authState.workspaceForAuth && <Button type="secondary" size="lg" text={"Cancel"} onClick={async () => {

						const isReset = await new CompanyAPI().resetWorkspaceForAuth()
						if (isReset) {
							setAuthState({ workspaceForAuth: undefined });

						}

					}} customClass={"w-full my-2"} />
				}

				{
					/* authentStrategy == AuthentStrategy.DELIVER ? (
						<div className="flex flex-col items-center mt-2" onClick={() => setAuthentStrategy(AuthentStrategy.SAML)}>
							<div className="flex w-full justify-center border-1 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
								<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="#c22e33"><path d="m11.6485 3c.2201.20009.4408.39969.6614.59929.4906.4437.9814.88759 1.4652 1.33681 3.1394 2.91636 5.7973 6.1976 7.7969 9.9734.102.1936.1948.3917.2876.5899.0464.099.0927.1981.1403.2966l-.1544.0835-.1159-.168c-.1004-.1453-.2007-.2906-.299-.4371l-.2106-.3126c-1.4559-2.1622-2.9141-4.3278-4.9166-6.05843-.5981-.51646-1.2786-.97511-1.9905-1.32199-1.4487-.70789-2.7377-.42525-3.8435.73872-1.05081 1.10616-1.59652 2.4795-2.08988 3.8773-.06359.18-.13083.3585-.19811.537-.03929.1043-.07867.2089-.11727.3135l-.24602-.0796c.14214-.5087.27425-1.021.40635-1.5333.29963-1.1619.59927-2.3239 1.01614-3.44379.44485-1.1927 1.00319-2.34345 1.56159-3.49422.2415-.49785.4831-.99574.7155-1.49699z" /><path d="m12.6406 17.3557c-2.3228 1.1241-7.94345 2.2855-10.60916 1.9875-.43578-2.3472 3.83956-13.34846 5.93993-15.01477-.05271.12192-.09658.22648-.13546.31914-.07499.17873-.13144.31326-.19693.44271l-.0951.18753c-1.08383 2.13699-2.16941 4.27742-2.72503 6.62159-.19106.8055-.31931 1.6432-.32585 2.468-.0144 1.9592 1.17647 3.2915 3.16691 3.375.95866.0403 1.9243-.063 2.88989-.1662.4197-.0449.8394-.0898 1.2585-.1229.1526-.012.3041-.0316.5017-.0571.0971-.0126.2055-.0266.3306-.0418z" /><path d="m6.0359 21.6799c-.63535.0496-1.27066.0991-1.90422.1582h-.00131c5.85226.5692 11.47153-.406 17.01503-2.4037-1.8073-3.2581-4.2414-5.8327-7.0733-8.0695.0367.1421.1194.246.2012.3487.0232.0292.0464.0583.0684.0881.2427.3303.4974.6544.7521.9785.5846.7439 1.1694 1.4878 1.6087 2.3066 1.1124 2.0749.5091 3.8696-1.6685 4.8178-1.4317.6244-2.9955 1.0496-4.5436 1.3027-1.47034.2397-2.96254.3561-4.4545.4726z" /></g></svg>
								<span className="ms-2">Sign in with SAML</span>
							</div>
						</div>
					) : <></> */
					// TODO uncomment while authent Deliver with passport is ready
					// <div className="flex flex-col items-center mt-2" onClick={() => setAuthentStrategy(AuthentStrategy.DELIVER)}>
					// 	<div className="flex w-full justify-center border-1 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
					// 		<Logo customClass="text-center" iconOnly={true} size="small"/>
					// 		<span className="ms-2">Sign in with Deliver</span>
					// 	</div>
					// </div> 
				}
			</div>
		</div>
	);
}

export { AuthPage }