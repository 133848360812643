import { FC, ReactNode, useEffect, useState } from "react"
import { useOutsideClick } from "../../hooks/useOuterClick"
import { Button } from "./buttons/Button"

type Props = {
	title: string,
	message: string | ReactNode,
	type?: "default" | "destructive"
	visible: boolean
	primaryButtonTitle: string,
	secondaryButtonTitle?: string,
	primaryCallback: () => void,
	secondaryCallback?: () => void,
	onClose?: () => void,
	closable?: boolean
}

const Alert: FC<Props> = (props) => {


	const [shake, setShake] = useState(false)

	/**
	* Callback used to close the dropdown when user click outside
	* see `useOutsideClick` below
	*/
	const closeOnOutside = () => {
		if (props.closable) {
			props.onClose?.()
		}
	};
	/**
	*  Reference of the dropdown menu div
	*	use to detect if user click outside of the element
	* 	to hide/close dropdown 
	*/
	const modalRef = useOutsideClick(closeOnOutside)


	// Effect to handle ESC key press
	useEffect(() => {

		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				if (props.closable === false) {
					setShake(true)
					setTimeout(() => { setShake(false); }, 1000);

				}
				else {
					props.onClose?.();
				}
			}
		};

		// Add event listener for keydown
		window.addEventListener('keydown', handleKeyDown);

		// Cleanup event listener on unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [props]);

	return <>
		{props.visible && (
			// Todo find better way to size modal than max-w-[40%] and max-h-[40%]
			<div className="fixed inset-0 z-[81] flex w-screen overflow-y-auto bg-gray-500 bg-opacity-75">

				<div ref={modalRef} className={`animate-fadeIn ${shake ? "animate-shake" : ""} z-50 m-auto min-w-72 max-w-96 p-6 h-fit w-fit bg-white flex flex-col justify-between border shadow-2xl rounded-lg`}>
					<p className="font-extrabold text-lg pb-3">{props.title}</p>
					<p className="pb-5 text-gray-700 font-medium">{props.message}</p>

					<div className="flex justify-end space-x-2">
						{props.secondaryButtonTitle && (
							<Button type="secondary" text={props.secondaryButtonTitle} onClick={(event) => {
								event.preventDefault()
								if (props.secondaryCallback)
									props.secondaryCallback()
							}} />
						)
						}

						<Button type={props.type === "destructive" ? "destructive" : "dark"} text={props.primaryButtonTitle} onClick={(event) => {
							event.preventDefault()
							if (props.primaryCallback)
								props.primaryCallback()
						}} />
					</div>
				</div>
			</div>
		)}
	</>
}

Alert.defaultProps = {
	visible: false,
	closable: true,
	type: "default"
}

export { Alert }