import { FC, useCallback, useEffect, useState } from "react"
import { Label } from "../../../../_components/Label"
import { TextInput } from "../../../../_components/TextInput"
import { Build, BuildStatus } from "../../../../../data/model/Build"
import { DropDownField, DropDownItem } from "../../../../_components/Dropdown"
import { XCircleIcon } from "@heroicons/react/24/outline"
import { Tooltip } from "../../../../_components/Tooltip"
import { observer } from "mobx-react-lite"
import { AppPageViewModel } from "../AppPageViewModel"

type Props = {
	viewModel: AppPageViewModel
}

export type Filters = {
	appVersion: string | "All"
	status: string | "All"
	issueId: string | "All"
}

const Filter: FC<Props> = ({ viewModel }) => {

	const [filters, setFilters] = useState<Filters>({
		appVersion: 'All',
		status: 'All',
		issueId: "All"
	})

	const appVersions = new Set<string>()
	const issueIds = new Set<string>()

	viewModel.builds.forEach(build => {
		appVersions.add(build.version)
		issueIds.add(build.customerIssue)
	})

	const filterBuilds = useCallback((() => {

		console.debug(JSON.stringify(filters))

		var status = (() => {
			switch (filters.status) {
				case "New": { return BuildStatus.NEW }
				case "Validated": { return BuildStatus.OK }
				default: { return BuildStatus.KO }
			}
		})

		

		const filteredBuilds = viewModel.builds.filter(build => {
			return (filters?.appVersion === 'All' || build.version === filters?.appVersion)
				&& (filters?.status === 'All' || build.status === status())
				&& (filters?.issueId === 'All' || build.customerIssue === filters?.issueId)
		})

	viewModel.filteredBuilds = filteredBuilds

	}), [filters, viewModel.builds])

	useEffect(() => {
		if (filters?.appVersion !== undefined
			|| filters?.status !== undefined || filters?.issueId !== undefined) {
			filterBuilds()
		}
	}, [filters.appVersion, filters.status, filters.issueId, filterBuilds])


	return (
		<div className="w-full">
			<div className="inline-flex gap-4 items-center">
				<div>
					<Label htmlFor={""} label="App version" />
					<div className="rounded-md">
						<DropDownField defaultValue={filters.appVersion} customClass="mb-4" onSelect={(value) => {
							setFilters({
								appVersion: value,
								status: filters.status,
								issueId: filters.issueId
							})
						}}>
							<DropDownItem value="All" title="All" />
							{
								Array.from(appVersions).map((appVersions, index) => {
									return <DropDownItem key={index + appVersions} value={appVersions} title={appVersions} />
								})
							}
						</DropDownField>
					</div>
				</div>
				<div>
					<Label htmlFor={""} label="Status" />
					<DropDownField defaultValue={filters.status} customClass="mb-4" onSelect={(value) => {

						setFilters({
							appVersion: filters.appVersion,
							// env: filters.env,
							status: value,
							issueId: filters.issueId
						})
					}}>
						<DropDownItem value="All" title="All" />
						<DropDownItem value={"New"} title={"New"} />
						<DropDownItem value={"Validated"} title={"Validated"} />
						<DropDownItem value={"Refused"} title={"Refused"} />
					</DropDownField>
				</div>
				<div>
					<Label htmlFor={""} label="Issue ID" />
					<DropDownField defaultValue={filters.issueId} customClass="mb-4" onSelect={(value) => {
						setFilters({
							appVersion: filters.appVersion,
							status: filters.status,
							issueId: value
						})
					}}>
						<DropDownItem value="All" title="All" />
						{
							Array.from(issueIds).map((issueId, index) => {
								return <DropDownItem key={index + issueId} value={issueId} title={issueId} />
							})
						}
					</DropDownField>
				</div>
				<div>
					<Tooltip content={"Clear filters"}>
						<XCircleIcon className="h-6 w-6" onClick={() => {
							setFilters({
								appVersion: "All",
								status: "All",
								issueId: "All"
							})
						}} />
					</Tooltip>
				</div>
			</div>
		</div>
	)
}

export { Filter }