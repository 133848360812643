import { FC, ReactNode } from "react"

enum BadgeType {
	NEUTRAL = "neutral",
	ERROR = "error",
	DANGEROUS = "dangerous",
	HIGH = "high",
	WARNING = "warning",
	SUCCESS = "success",
	GOOD = "good",
	NORMAL = "normal",
	INFO = "info",
	INFOLIGHT = "infolight",
	PURPLE = "purple",
	SECURE = "secure",
	ERRORLIGHT = "errorlight"
}

type Props = {
	type: BadgeType
	text: string | ReactNode
	customClass?: string
	size?: "sm" | "default"
}

const Badge: FC<Props> = ({ type, text, size, customClass }) => {
	const style = () => {

		let style = `inline-flex items-center rounded px-2 ${ size == "default" ? "py-0.5" : "py-0.5" } text-xs font-semibold ring-1`

		switch (type) {
			case BadgeType.NEUTRAL: {
				return `${style} bg-gray-50 text-gray-600 ring-gray-500/10`
			}
			case BadgeType.ERROR:
			case BadgeType.DANGEROUS:
			case BadgeType.HIGH: {
				return `${style} bg-red-50 text-red-700 ring-red-600/10`
			}
			case BadgeType.WARNING: {
				return `${style} bg-yellow-50 text-yellow-800 ring-yellow-600/20`
			}
			case BadgeType.SUCCESS:
			case BadgeType.GOOD:
			case BadgeType.NORMAL: {
				return `${style} bg-green-50 text-green-700 ring-green-600/20`
			}
			case BadgeType.INFO: {
				return `${style} bg-blue-50 text-blue-700  ring-blue-700/10`
			}
			case BadgeType.INFOLIGHT: {
				return `${style} bg-indigo-50 text-indigo-700 ring-indigo-700/10`
			}
			case BadgeType.PURPLE:
			case BadgeType.SECURE: {
				return `${style} bg-purple-50 text-purple-700 ring-purple-700/10`
			}
			case BadgeType.ERRORLIGHT: {
				return `${style} bg-pink-50 text-pink-700 ring-pink-700/10`
			}
			default: {
				return `${style} bg-gray-50 text-gray-600 ring-gray-500/10`
			}
		}
	}

	return (
		<div className={`${style()} + ' ' + ${customClass}  `}>{text}</div>
	)
}

Badge.defaultProps = {
	customClass: "",
	size: "default"
}

export { Badge, BadgeType }