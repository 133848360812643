import { FC } from "react"
import { Build, BuildStatus } from "../../../../data/model/Build"
import { Badge, BadgeType } from "../../../_components/Badge"
import { Button } from "../../../_components/buttons/Button"
import { CloudArrowDownIcon } from "@heroicons/react/24/outline"
import Moment from "react-moment"
import { Avatar } from "../../../_components/avatar/Avatar"
import { App, AppType } from "../../../../data/model/App"

type MobileBuildItemProps = {
	build: Build
	app: App
}
const MobileBuildItem: FC<MobileBuildItemProps> = ({build, app}) => {

	const { REACT_APP_API_URL } = process.env

	const statusBadge = () => {
		if (build.status === BuildStatus.NEW) {
			return <Badge size="sm" type={BadgeType.INFO} text={"New"} />
		} else if (build.status === BuildStatus.OK) {
			return <Badge size="sm" type={BadgeType.GOOD} text={"Validated"} />
		} else if (build.status === BuildStatus.TEST_IN_PROGRESS) {
			return <Badge size="sm" type={BadgeType.WARNING} text={"Testing"} />
		}
		else {
			return <Badge type={BadgeType.ERROR} text={" Refused"} />
		}
	}

	const install = () => {
		if (app.platform == AppType.IOS)  {
			const url = "itms-services://?action=download-manifest&url="+REACT_APP_API_URL+"/build/"+build.id+"/ios"
			window.open(url, "_blank")
		}
		else {
			const url = REACT_APP_API_URL+"/binary/"+build.binary
			window.open(url, "_blank")
		}
	}

	return (
		<div className="card border-1 border-[#E4E6EF] p-4 rounded-lg w-full" >
				{/* <AppAvatar app={app} size="lg" /> */}
				<div className="w-full flex flex-col gap-2">
					<div className="w-full flex items-center">
						<div className="w-full flex flex-row justify-between">
							<div className="flex flex-row gap-1 items-center">
								<h3 className="font-bold text-lg me-1">{build.version}</h3>
								{ statusBadge() }
							</div>
							
							<Button type="primary" onClick={install}>
								<div className="flex flex-row gap-1 items-center">
									<CloudArrowDownIcon className="size-4" />
									<p>Install</p>
								</div>
							</Button>
						</div>
						{/* {app.platform === AppType.IOS ? <AppleLogo className="size-4 mb-1" /> : <AndroidLogo className="size-4" />} */}
					</div>
					<p className="text-sm font-medium text-gray-400"><Moment format="DD MMM YYYY [at] HH[h]mm">{build.createdAt}</Moment> </p>
					<div className="flex flex-row justify-between">
						<div className="flex flex-col gap-1">
							<p className="text-xs">Publisher</p>
							{
								build.committer 
									? (<Avatar user={build.committer} />)
									: (<div className={`flex items-center justify-center bg-slate-100 text-black text-xs font-bold rounded border-1`}>
										{`${build.committerEmail[0] || ""}`.toUpperCase() || "?"}
										</div>
									)
							}
							
						</div>
						<div className="flex flex-row gap-2">
							<div className="flex flex-col gap-1">
								<p className="text-xs">Pipeline</p>
								<Badge type={BadgeType.NEUTRAL} text={build.pipelineId} />
							</div>
							<div className="flex flex-col gap-1">
								<p className="text-xs">Commit hash</p>
								<Badge type={BadgeType.NEUTRAL} text={build.commitHash} />
							</div>
						</div>
					</div>
					<div className="text-sm mt-3">
						<p className="font-semibold">Branch</p>
						<p className="text-sm">{build.branch}</p>
					</div>
					<div className="text-sm mt-3">
						<p className="font-semibold">Message</p>
						<p className="text-sm">{build.commitMessage}</p>
					</div>
				</div>
			
		</div>
	)
}

export { MobileBuildItem }