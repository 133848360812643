import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { StorePage } from "../pages/store/store/StorePage"
import { MasterLayout } from "../_layout/MasterLayout"
import { IssuesPage } from "../pages/issues/IssuesPage"
import { CrashesPage } from "../pages/crashes/CrashesPage"
import { LivelogPage } from "../pages/livelog/LivelogPage"
import { LocalizationPage } from "../pages/localization/LocalizationPage"
import { SettingsLayout } from "../pages/settings/SettingsLayout"
import { NetworkPage } from "../pages/network/NetworkPage"
import { RemoteConfigPage } from "../pages/remoteconfig/RemoteConfigPage"
import { BuildLayout } from "../pages/store/build/_layout/BuildLayout"
import { IssuesPage as BuildIssue } from "../pages/store/build/issues/BuildIssues"
import { BuildSecurity } from "../pages/store/build/security/BuildSecurity"
import { BuildLive, LiveDebugType } from "../pages/store/build/BuildLive"
import { BuildPerformance } from "../pages/store/build/BuildPerformance"
import { BuildCrash } from "../pages/store/build/crashes/BuildCrash"
import { SecurityPage } from "../pages/security/SecurityPage"
import { TodoPage } from "../pages/Todo/TodoPage"
import { ProfilePage } from "../pages/profile/ProfilePage"
import { Components } from "../../Components"
import { AppPage } from "../pages/store/app/AppPage"
import { Company } from "../pages/settings/company/Company"
import { Apps } from "../pages/settings/apps/Apps"
import CompanyViewModel from "../pages/settings/company/CompanyViewModel"
import { useAuth } from "../../hooks/useAuth"
import { Members } from "../pages/settings/members/Members"
import { Teams } from "../pages/settings/teams/Teams"
import { MembersViewModel } from "../pages/settings/members/MembersViewModel"
import { TeamsViewModel } from "../pages/settings/teams/TeamsViewModel"
import { BuildOverview } from "../pages/store/build/overview/BuildOverview"
import { isMobile } from 'react-device-detect';
import { MobileStore } from "../pages/mobile/store/MobileStore"
import { MobileBuildsList } from "../pages/mobile/builds/MobileBuildsList"

const PrivateRoutes: FC = () => {

	const { workspace } = useAuth()

	return (
		<>
		{
			isMobile == true
			?(
				<Routes>
					<Route path='auth/*' element={<Navigate to='/store' />} />
					<Route path='store' element={ <MobileStore />} />
					<Route path='store/app/:appId' element={ <MobileBuildsList />} />
				</Routes>
			)
			:(
				<Routes>
					<Route path='auth/*' element={<Navigate to='/store' />} />
					<Route element={<MasterLayout />}>
						<Route path='store' element={<Outlet />}>
							<Route index element={<StorePage />} />
							<Route path='app/:appId' element={<Outlet />}>
								<Route index element={<AppPage />} />
								<Route path='build/:buildId/*' element={<BuildLayout />}>
									<Route index element={<Navigate to='overview' />} />
									<Route path='overview' element={<BuildOverview />} />
									<Route path='issue' element={<BuildIssue />} />
									<Route path='crash' element={<BuildCrash />} />
									<Route path='security' element={<BuildSecurity />} />
									<Route path='network' element={<BuildLive liveDebugType={LiveDebugType.NETWORK} />} />
									<Route path='live' element={<BuildLive liveDebugType={LiveDebugType.LOGS} />} />
									<Route path='performance' element={<BuildPerformance />} />
								</Route>
							</Route>
						</Route>
						<Route path='profile' element={<ProfilePage />} />
						<Route path='issues' element={<IssuesPage />} />
						<Route path='crashes' element={<CrashesPage />} />
						<Route path='security' element={<SecurityPage />} />
						<Route path='livelog' element={<LivelogPage />} />
						<Route path='network' element={<NetworkPage />} />
						<Route path='localization' element={<LocalizationPage />} />
						<Route path='remoteconfig' element={<RemoteConfigPage />} />
						<Route path='settings/*' element={<SettingsLayout />}>
							<Route index element={<Navigate to='company' />} />
							<Route path='company' element={<Company viewModel={new CompanyViewModel(workspace)} />} />
							<Route path='apps' element={<Apps />} />
							<Route path='members' element={<Members viewModel={new MembersViewModel(workspace)} />} />
							<Route path='teams' element={<Teams viewModel={new TeamsViewModel(workspace)} />} />
						</Route>
						<Route path='todos' element={<TodoPage />} />
					</Route>

					{/* Application settings is outside master layout because settings use a dedicated layout */}
					{/* <Route path='store/settings/*' element={<StoreSettingsRoutes />} /> */}
					{/* <Route path='store/app/:appId/settings/*' element={<AppSettingsRoutes />} /> */}
					<Route path='components/*' element={<Components />} />
				</Routes >
			)
		}
		</>
		
	)
}

export { PrivateRoutes }