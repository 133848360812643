import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo, useState } from "react";
import { useMobileOrientation } from "react-device-detect";
import { AppPageViewModel } from "../../store/app/AppPageViewModel";
import { Build } from "../../../../data/model/Build";
import { AppAvatar } from "../../../_components/AppsAvatar";
import { MobileAppCard } from "../store/MobileStore";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon, UserIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { MobileBuildItem } from "./MobileBuildItem";
import { Button } from "../../../_components/buttons/Button";
import { MobileProfile } from "../profile/MobileProfile";
import { useAuth } from "../../../../hooks/useAuth";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";


const MobileBuildsList: FC = observer(() => {

	const { appId } = useParams()
	const { setWorkspace } = useAuth()
	const navigate = useNavigate();
	
	const [isOpen, setIsOpen] = useState(false);

  	const openModal = () => setIsOpen(true);
  	const closeModal = () => setIsOpen(false);


	const { isLandscape } = useMobileOrientation()
	const viewModel = useMemo(() => new AppPageViewModel(appId), [appId]);

	useEffect(() => {
		viewModel.load()
	}, [appId])

	return (
		
		<div className="h-full flex flex-col">
			<header className={`w-full ${isLandscape ? "h-[80px] pt-safe-left" : "h-[100px] pt-safe-top" }  bg-white fixed border px-3 pb-5 flex flex-row justify-between items-center`}>
				<div>
					<button onClick={ () => { navigate(-1) } }>
					<ChevronLeftIcon className={`size-6`} />
					</button>
				</div> 
				<h1 className="text-2xl font-black text-center">Builds</h1>
				<button onClick={openModal} >
					<UserIcon className="size-5"/>
				</button>
			</header>
			<div className={`p-4 bg-white fixed w-full ${isLandscape ? "mt-[80px] px-16" : "mt-[100px]"} `}>
				{ 
					viewModel.app
					? (
						<MobileAppCard app={viewModel.app}/>
					)
					: (null)
				}	
			</div>
			<div className={`flex flex-col p-4 ${isLandscape ? "mt-[80px] px-16" : "mt-[200px]"} `}>
				{
					viewModel.app && viewModel.builds.length > 0
					? (
						<>
							<h3 className="font-bold text-lg pt-4 pb-2">Latest build</h3>
							<MobileBuildItem build={viewModel.builds[0]} app={viewModel.app}/>
						</>
					):(
						<div className="mt-6 flex flex-row items-center gap-1 bg-slate-100 p-5 rounded border border-slate-200">
							<SquaresPlusIcon className="size-6" />
							<p className="font-semibold">There is no build for this app</p>
						</div>
					)
				}
				{
					viewModel.app && viewModel.builds.length > 1 ? (
						<>
						<h2 className="font-bold text-lg pt-4 pb-2">Previous builds</h2>
						<div className="flex flex-col gap-2">
						{ viewModel.builds.slice(1).map( (b) => <MobileBuildItem build={b} app={viewModel.app!}/> ) }
						</div>
						</>
					):(null)
				}
			</div>
	
			<MobileProfile isOpen={isOpen} onClose={closeModal} onChangeWorkspace={function (workspace): void {
				setWorkspace(workspace); // Set the selected workspace
				navigate(-1)
			} } />
		</div>
	)
})

export { MobileBuildsList }