import { FC, useState } from "react"
import { Logo } from "../_components/logo/logo"
import { SideMenuItem } from "./SideMenuItem"
import {
	Squares2X2Icon,
	BugAntIcon,
	ShieldCheckIcon,
	CloudArrowDownIcon,
	ExclamationTriangleIcon,
	WifiIcon,
	LanguageIcon,
	Cog8ToothIcon,
	GlobeAltIcon,
	BellAlertIcon
} from '@heroicons/react/24/outline'
import { Modal } from "../_components/Modal"

export type Props = {
	forceOpenSideMenu: boolean
}

const SideMenu: FC<Props> = ({ forceOpenSideMenu }) => {

	const [isNewsVisible, setIsNewsVisible] = useState(false)

	return (
		<>
		<aside id="default-sidebar" className={`fixed top-0 left-0 w-64 h-screen ${forceOpenSideMenu ? "transition-transform translate-x-0 bg-white" : "transition-transform -translate-x-full sm:translate-x-0"} aria-label="Sidebar`}>
			<div className="relative h-full flex flex-col justify-between px-6 py-4 overflow-y-auto border-r-1 border-r-gray-200 ">
				<div>
				<Logo customClass="mb-[50px]" size="xl" />
				<ul className="space-y-2 font-medium">
					{ /** Store */}
					<SideMenuItem
						name={"Store"}
						to="/store"
						icon={<Squares2X2Icon className="size-6" />} />

					{ /** Issues */}
					<SideMenuItem
						name={"Issues"}
						to="/issues"
						icon={<BugAntIcon className="size-6" />} />

					{ /** Crashes */}
					<SideMenuItem
						name={"Crashes"}
						to="/crashes"
						icon={<ExclamationTriangleIcon className="size-6" />} />

					{ /** Security */}
					<SideMenuItem
						name={"Security"}
						to="/security"
						icon={<ShieldCheckIcon className="size-6" />} />

					{ /** Live log */}
					<SideMenuItem
						name={"Live log"}
						to="/livelog"
						icon={<WifiIcon className="size-6" />} />

					{ /** Network */}
					<SideMenuItem
						name={"Network"}
						to="/network"
						icon={<CloudArrowDownIcon className="size-6" />} />

					{ /** Localization */}
					<SideMenuItem
						name={"Localization"}
						to="/localization"
						icon={<LanguageIcon className="size-6" />} />

					{ /** Remote config */}
					<SideMenuItem
						name={"Remote config"}
						to="/remoteconfig"
						icon={<GlobeAltIcon className="size-6" />} />

					{ /** Settings */}
					<SideMenuItem
						name={"Settings"}
						to="/settings"
						icon={<Cog8ToothIcon className="size-6" />} />
				</ul>
				</div>
				{ /** Components demo */}
				<button className="bg-slate-100 p-2 text-sm font-bold rounded-md flex flex-row items-center justify-between" onClick={ () => setIsNewsVisible(true) }>
						<div className="flex flex-row items-center gap-1">
							<BellAlertIcon className="size-5" />
							<p>News</p>
						</div>
						<div className="rounded px-1.5 bg-red-500 text-white">
							3
						</div>

				</button>
			</div>
		</aside>
		<Modal title="News" message={""} visible={isNewsVisible} onClose={() => setIsNewsVisible(false) }>
			<p> Ici les news de la semaines</p>
		</Modal>
		</>
	)
}

export { SideMenu }
