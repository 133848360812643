import { FC, useEffect, useState } from "react"
import { Build, BuildStatus } from "../../../../data/model/Build"
import { Badge, BadgeType } from "../../../_components/Badge"
import { Button } from "../../../_components/buttons/Button"
import { CloudArrowDownIcon, XMarkIcon } from "@heroicons/react/24/outline"
import Moment from "react-moment"
import { useAuth } from "../../../../hooks/useAuth"
import { Avatar } from "../../../_components/avatar/Avatar"
import { DropDownField, DropDownItem } from "../../../_components/Dropdown"
import { Link, useNavigate } from "react-router-dom"
import { Label } from "../../../_components/Label"
import { UserAPI } from "../../../../data/api/UserAPI"
import { Company } from "../../../../data/model/Company"

type Props = {
	isOpen: boolean
	onChangeWorkspace: (workspace: Company) => void
	onClose: () => void
}
const MobileProfile: FC<Props> = ({isOpen, onClose, onChangeWorkspace}) => {

	const { user, workspace } = useAuth()
	const [companies, setCompanies] = useState<Company[] | undefined>()
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		(async () => {
			const userAPI = new UserAPI()
			const companies = await userAPI.getCompaniesForUser("me")
			setCompanies(companies)
		})()
	}, [user])

	return (
		<div className={`fixed inset-0 bg-black bg-opacity-50 z-40 flex justify-center items-end transition-opacity duration-300 ${ isOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none" }`} onClick={onClose} >
			{/* Modal Content */}
			<div className={`bg-white w-full mx-auto  pb-10 rounded-t-2xl shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${ isOpen ? "translate-y-0" : "translate-y-full" }`} onClick={(e) => e.stopPropagation()}  >
				<div className="p-4 border-b flex justify-between items-center">
					<h3 className="font-extrabold text-xl pt-3 pb-1">Profile</h3>
					<button onClick={onClose}><XMarkIcon className="size-5" /></button>
				</div>
				<div className="p-4 pb-6">
					<>
					{
						user ? (
							<div className="flex flex-row gap-2 items-center">
								<Avatar user={user} />
								<div className="flex flex-col">
									<span className="font-bold">{user?.firstname} {user?.lastname}</span>
									<span className="">{user?.email}</span>
								</div>
							</div>
						):(null)
					}
					</>
						<div className='border-t-1 mt-3 py-4'>
							<p className="font-semibold text-sm pb-1">Workspace</p>
							<div className="w-full mb-6">
								<select 
									className="border w-full p-2 rounded-md"
									onChange={(e) => {
										const selectedIndex = e.target.selectedIndex;
										const selectedCompany = companies ? companies[selectedIndex] : undefined;
										if (selectedCompany) {
											onChangeWorkspace(selectedCompany)
										}
									}}
									>
									{companies?.map((company, index) => (
										<option key={company.id} value={index} selected={workspace?.id == company.id }>
										{company.name || company.id}
										</option>
									))}
									</select>
							</div>
						</div>
						<Button type="dark" onClick={ () => navigate("/auth") } customClass="w-full">Sign out</Button>
				</div>
			</div>
		</div>
	)
}

export { MobileProfile }